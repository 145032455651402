import React, { Fragment, useContext, useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../../api/API";
import {
  createParentCompany,
  searchParentCompanyByName,
} from "../../../api/BarcodeScan";
import { listCountries } from "../../../api/SignUp";
import { DashboardPopUpContext } from "../../../dashboard-components/context/DashboardPopUpContext";
import DashboardPopUp from "../../../dashboard-components/DashboardPopUp";
import { useFormFieldOnChange } from "../../../utils/FormHooks";
import { input, BarcodeSearchButton } from "./ProductScanNewStyle";
import { Transition, Dialog } from "@headlessui/react";

const ParentCompanyModal = ({
  assignToCallbackParam,
  assingToCallBack,
  showCompromisedModal,
  closeCompromisedModal,
  refreshCallback,
}) => {
  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [companyName, setCompanyName, onChangeCompanyName] =
    useFormFieldOnChange("");

  const [newName, setNewName, onChangeNewName] = useFormFieldOnChange("");
  const [newPhone, setNewPhone, onChangeNewPhone] = useFormFieldOnChange("");
  const [newEmail, setNewEmail, onChangeNewEmail] = useFormFieldOnChange("");
  const [newWebsite, setNewWebsite, onChangeNewWebsite] =
    useFormFieldOnChange("");
  const [newAddress1, setNewAddress1, onChangeAddress1] =
    useFormFieldOnChange("");
  const [newAddress2, setNewAddress2, onChangeAddress2] =
    useFormFieldOnChange("");
  const [newCity, setNewCity, onChangeCity] = useFormFieldOnChange("");
  const [newState, setNewState, onChangeState] = useFormFieldOnChange("");
  const [newPostalCode, setNewPostalCode, onChangePostalCode] =
    useFormFieldOnChange("");
  const [newCountry, setNewCountry, onChangeNewCountry] =
    useFormFieldOnChange("");

  const [countries, setCountries] = useState(null);

  const [showAddNewCompanyForm, setShowAddNewCompanyForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [addNewErrorMsg, setNewErrorMsg] = useState(null);

  const searchCompanies = async (e) => {
    e.preventDefault();

    if (companyName.trim().length === 0 || loading === true) return;

    setErrorMsg(null);
    setSelectedCompany(null);

    setLoading(true);

    const res = await searchParentCompanyByName(companyName);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setShowAddNewCompanyForm(true);

    if (res.data.length === 0) {
      setErrorMsg("No companies found");
    }

    setCompanies(res.data);
  };

  const confirmClick = () => {
    setErrorMsg(null);
    if (selectedCompany === null) {
      setErrorMsg("Please select a company");
      return;
    }
    // grabbing of the id
    let companyID = selectedCompany?.CompanyID;
    assignCompanyTo(companyID, setErrorMsg);
  };

  const addNewClick = async () => {
    setNewErrorMsg(null);

    if (!newName.trim().length) {
      setNewErrorMsg("Please provide a name for the company");
      return;
    }

    setLoading(true);
    // the create company function recieves all the parameterst to create a new company
    const createCompany = await createParentCompany(
      newName,
      newPhone,
      newEmail,
      newWebsite,
      newCountry,
      newAddress1,
      newAddress2,
      newCity,
      newState,
      newPostalCode
    );
    if (createCompany.status !== HTTP_STATUS_CODES.OK) {
      setNewErrorMsg("An occurred while creating the parent company");
      setLoading(false);
      return;
    }

    assignCompanyTo(createCompany.data.CompanyID, setNewErrorMsg);
  };

  const assignCompanyTo = async (companyID, errorMsgSetter) => {
    setLoading(true);
    const res = await assingToCallBack(companyID, assignToCallbackParam);
    setLoading(false);

    if (res.status === HTTP_STATUS_CODES.OK) {
      hidePopUp();
      refreshCallback();
    } else {
      errorMsgSetter("An occurred while assigning the record");
    }
  };

  const selectCompany = (company) => {
    setErrorMsg(null);
    setSelectedCompany(company);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);
    const res = await listCountries();
    setLoading(false);

    setCountries([]);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      setNewErrorMsg("An occurred while fetching countries");
      return;
    }

    setCountries(res.data);
  };

  return (
    <Transition appear show={showCompromisedModal === true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        onClose={closeCompromisedModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-3 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between border-b pb-3 border-b-black items-start">
                  <h5 className="font-semibold text-sm">
                    Search for parent companies
                  </h5>
                  <div
                    onClick={closeCompromisedModal}
                    className="cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="space-y-3 h-96 overflow-y-auto px-1">
                  <div>
                    <form
                      onSubmit={searchCompanies}
                      className="flex gap-2 items-center my-3"
                    >
                      <input
                        autoFocus
                        onChange={onChangeCompanyName}
                        value={companyName}
                        className="rounded py-1.5 px-2 border border-gray"
                        type="text"
                        placeholder="Enter the company name"
                      />
                      &nbsp;
                      <button
                        className="bg-primary text-white rounded px-3 py-1.5"
                        disabled={loading === true}
                        onClick={searchCompanies}
                      >
                        Search
                      </button>
                    </form>
                    {errorMsg !== null && (
                      <div className="text-red-500 mb-2">{errorMsg}</div>
                    )}
                    {companies !== null ? (
                      <div className="overflow-auto mt-3 h-40 pb-4 w-full">
                        {companies?.map((comp, index) => (
                          <div key={index} className="mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              onChange={() => {
                                selectCompany(comp);
                              }}
                              checked={
                                selectedCompany?.CompanyID == comp.CompanyID
                              }
                            />
                            <span>{comp.CompanyName}</span>
                            {comp.EntityGLN !== null
                              ? " - " + comp.EntityGLN
                              : ""}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <button
                      onClick={confirmClick}
                      className={`rounded px-3 py-1.5 text-white ${
                        loading ? "bg-gray-500" : "bg-primary"
                      }`}
                      disabled={loading === true}
                    >
                      Confirm
                    </button>
                  </div>
                  <div>
                    <p className="font-bold text-base">Add new company</p>
                    {addNewErrorMsg !== null && (
                      <div className="text-red-500 text-sm py-2">
                        {addNewErrorMsg}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeNewName}
                      value={newName}
                      type="text"
                      placeholder="Name"
                    />

                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeNewPhone}
                      value={newPhone}
                      type="text"
                      placeholder="Phone"
                    />

                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeNewEmail}
                      value={newEmail}
                      type="text"
                      placeholder="Email"
                    />

                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeNewWebsite}
                      value={newWebsite}
                      type="text"
                      placeholder="Website"
                    />
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeAddress1}
                      value={newAddress1}
                      type="text"
                      placeholder="Address Street 1"
                    />
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeAddress2}
                      value={newAddress2}
                      type="text"
                      placeholder="Address Street 2"
                    />
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeCity}
                      value={newCity}
                      type="text"
                      placeholder="Address City"
                    />
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangePostalCode}
                      value={newPostalCode}
                      type="text"
                      placeholder="Address Postcode"
                    />
                    <input
                      className="rounded border border-gray py-1.5 px-3"
                      disabled={!showAddNewCompanyForm}
                      onChange={onChangeState}
                      value={newState}
                      type="text"
                      placeholder="Address State"
                    />

                    {countries !== null && (
                      <select
                        className="rounded border border-gray py-1.5 px-3"
                        disabled={!showAddNewCompanyForm}
                        value={newCountry}
                        onChange={onChangeNewCountry}
                      >
                        <option value="">-- SELECT --</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country.CountryCode}>
                            {country.CountryName ?? "NOT DEFINED"}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <button
                    style={{
                      cursor: !showAddNewCompanyForm ? "auto" : "pointer",
                    }}
                    className={`${
                      !showAddNewCompanyForm ? "cursor-auto" : "cursor-pointer"
                    } ${
                      !showAddNewCompanyForm ? "bg-gray-500" : "bg-primary"
                    } rounded px-3 text-white py-1.5 mt-3`}
                    disabled={!showAddNewCompanyForm}
                    onClick={() => addNewClick()}
                  >
                    Add new
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ParentCompanyModal;
