import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as RoutesReactRouter,
} from "react-router-dom";

import SignUpPage from "../pages/SignUpPage/SignUpPage";
import SignInPage from "../pages/SignInPage/SignInPage";
import NotLoggedInHomePage from "../pages/HomePage/HomePage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import SupplierRoute from "./SupplierRoute";

import SignupHome from "../private-pages/Signup/SignupHome";
import SignupIntro from "../private-pages/Signup/Intro/SignupIntro";
import SignupType from "../private-pages/Signup/Type/SignupType";
import SignupSubscription from "../private-pages/Signup/Subscription/SignupSubscription";
import SignupUsers from "../private-pages/Signup/Users/SignupUsers";

import EntityHome from "../private-pages/Entity/EntityHome";
import EntityUsers from "../private-pages/Entity/Users/EntityUsers";
import EntitySubscription from "../private-pages/Entity/Subscription/EntitySubscription";
import EntityLoyalty from "../private-pages/Entity/Loyalty/EntityLoyalty";

import AccountHome from "../private-pages/Account/AccountHome";
import AccountDetails from "../private-pages/Account/Details/AccountDetails";
import AccountTransactions from "../private-pages/Account/Transactions/AccountTransactions";

import SupplierHome from "../private-pages/Supplier/SupplierHome";
import SupplierAdvertising from "../private-pages/Supplier/Advertising/SupplierAdvertising";
import SupplierAnalytics from "../private-pages/Supplier/Analytics/SupplierAnalytics";
import SupplierLocations from "../private-pages/Supplier/Locations/SupplierLocations";
import SupplierOverrides from "../private-pages/Supplier/Overrides/SupplierOverrides";
import SupplierPrefixes from "../private-pages/Supplier/Prefixes/SupplierPrefixes";
import SupplierProducts from "../private-pages/Supplier/Products/SupplierProducts";

import RetailerHome from "../private-pages/Retailer/RetailerHome";
import RetailerAdvertising from "../private-pages/Retailer/Advertising/RetailerAdvertising";
import RetailerAnalytics from "../private-pages/Retailer/Analytics/RetailerAnalytics";
import RetailerProducts from "../private-pages/Retailer/Products/RetailerProducts";
import RetailerStores from "../private-pages/Retailer/Stores/RetailerStores";

import ServiceHome from "../private-pages/Service/ServiceHome";
import ServiceAdvertising from "../private-pages/Service/Advertising/ServiceAdvertising";
import ServiceAnalytics from "../private-pages/Service/Analytics/ServiceAnalytics";
import ServiceStores from "../private-pages/Service/Stores/ServiceStores";
import ServiceServices from "../private-pages/Service/Services/ServiceServices";

import ProductHome from "../private-pages/Product/ProductHome";
//import ProductScan from "../private-pages/Product/Scan/ProductScan";
import ProductBrands from "../private-pages/Product/Brands/ProductBrands";
import ProductBricks from "../private-pages/Product/Bricks/ProductBricks";
import ProductImages from "../private-pages/Product/Images/ProductImages";

import LoyaltyHome from "../private-pages/Loyalty/LoyaltyHome";
import LoyaltyProgram from "../private-pages/Loyalty/Program/LoyaltyProgram";
import LoyaltyPartners from "../private-pages/Loyalty/Partners/LoyaltyPartners";
import LoyaltyTechnicalHome from "../private-pages/Loyalty/Technical/LoyaltyTechnical";
import LoyaltyEndpoints from "../private-pages/Loyalty/Technical/LoyaltyEndpoints/LoyaltyEndpoints";
import LoyaltyImplementations from "../private-pages/Loyalty/Technical/LoyaltyImplementations/LoyaltyImplementations";
import LoyaltyIntegration from "../private-pages/Loyalty/Technical/LoyaltyIntegration/LoyaltyIntegration";

import DatabaseHome from "../private-pages/Database/DatabaseHome";
import DatabaseAPI from "../private-pages/Database/DatabaseAPI/DatabaseAPI";
import DatabaseBrowser from "../private-pages/Database/DatabaseBrowser/DatabaseBrowser";

import DatabaseUpdateHome from "../private-pages/DatabaseUpdate/DatabaseUpdateHome";
import GS1Global from "../private-pages/DatabaseUpdate/GS1Global/GS1Global";
import GS1AUS from "../private-pages/DatabaseUpdate/GS1AUS/GS1AUS";
import GS1USA from "../private-pages/DatabaseUpdate/GS1USA/GS1USA";

import SupportHome from "../private-pages/Support/SupportHome";

import SupportGS1Home from "../private-pages/Support/GS1/GS1Home";
import SupportGS1GPC from "../private-pages/Support/GS1/GS1GPC/GS1GPC";
import SupportGS12 from "../private-pages/Support/GS1/GS12/GS12";
import SupportGS13 from "../private-pages/Support/GS1/GS13/GS13";

import SupportGS1AUS from "../private-pages/Support/GS1AUS/GS1AUSHome";
import SupportGS1AUSImages from "../private-pages/Support/GS1AUS/GS1AUSImages/GS1AUSImages";
import SupportGS1AUS2 from "../private-pages/Support/GS1AUS/GS1AUS2/GS1AUS2";
import SupportGS1AUS3 from "../private-pages/Support/GS1AUS/GS1AUS3/GS1AUS3";

import SupportGS1USA from "../private-pages/Support/GS1USA/GS1USAHome";
import SupportGS1USAImages from "../private-pages/Support/GS1USA/GS1USAImages/GS1USAImages";
import SupportGS1USA2 from "../private-pages/Support/GS1USA/GS1USA2/GS1USA2";
import SupportGS1USA3 from "../private-pages/Support/GS1USA/GS1USA3/GS1USA3";

import TestingHome from "../private-pages/Testing/TestingHome";
import TestingSearch from "../private-pages/Testing/Search/TestingSearch";

import UserHome from "../private-pages/User/UserHome";
import UserProfile from "../private-pages/User/Profile/UserProfile";
import { useAuthContext } from "../context/AuthContext";
import CompleteRegistration from "../pages/CompleteRegistration/CompleteRegistration";
import CompleteSubscriptionRenewal from "../pages/CompleteSubscriptionRenewal/CompleteSubscriptionRenewal";
import AppCompleteRegistration from "../pages/AppCompleteRegistration/AppCompleteRegistration";
import GetStartedAccountTypeSelection from "../private-pages/GetStarted/GetStartedAccountTypeSelection";
import GetStartedAccountContactDetails from "../private-pages/GetStarted/GetStartedAccountContactDetails";
import GetStartedAccountDetails from "../private-pages/GetStarted/GetStartedAccountDetails";
import GetStartedAccountPrefix from "../private-pages/GetStarted/GetStartedAccountPrefix";
import GetStartedAccountPayment from "../private-pages/GetStarted/GetStartedAccountPayment";
import GetStartedAccountReview from "../private-pages/GetStarted/GetStartedAccountReview";
import RenewSubscription from "../private-pages/GetStarted/RenewSubscription";
import DashboardHome from "../private-pages/DashboardHome/DashboardHome";
import GetStartedAccountTypeSupplier from "../private-pages/GetStarted/GetStartedAccountTypeSupplier";
import GetStartedAccountTypeSupplierConfirmation from "../private-pages/GetStarted/GetStartedAccountTypeSupplierConfirmation";
import GetStartedAccountWaitingApproval from "../private-pages/GetStarted/GetStartedAccountWaitingApproval";
import EmployeeRoute from "./EmployeeRoute";
import MyProducts from "../dashboard-supplier/Product/MyProducts";
import ProductImport from "../dashboard-admin/Product/Import/ProductImport";
import GetStartedAccountTypeRetailer from "../private-pages/GetStarted/GetStartedAccountTypeRetailer";
import GetStartedAccountTypeServiceProvider from "../private-pages/GetStarted/GetStartedAccountTypeServiceProvider";
import GetStartedInvestorExpressionOfInterest from "../private-pages/GetStarted/GetStartedInvestorExpressionOfInterest";
import ProductScanNew from "../dashboard-admin/Product/Scan/ProductScanNew";
import SignupApproval from "../dashboard-admin/SignupApproval/SignupApproval";
import EODReport from "../dashboard-admin/EODReport/EODReport";
import GVDEditor from "../dashboard-admin/GVD/GVDEditor/GVDEditor";
import ValidateGVD from "../dashboard-admin/GVD/GVDValidation/GVDValidation";
import InvestorInterest from "../pages/InvestorInterest/InvestorInterest";
import HowYouShopMatters from "../pages/HowYouShopMatters/HowYouShopMatters";
import ProductsPage from "../pages/ProductsPage/ProductsPage";
import TermsConditions from "../pages/TermsConditions/TermsConditions";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import AppResetPassword from "../pages/AppResetPassword/AppResetPassword";
import DownloadOurApp from "../pages/DownloadOurApp/DownloadOurApp";
import UnderReview from "../pages/UnderReview";
import CompanyDetail from "../pages/CompanyDetail/CompanyDetail";
import SupplierProduct from "../pages/SupplierProduct/SupplierProduct";
import PageNotFound from "../pages/PageNotFound";
import ManageBrands from "../pages/ManageBrands/ManageBrands";
import Profile from "../pages/Profile";
import SignUpApprovalTab from "../pages/SignUpApproval/SignUpApproval";
import ViewApplication from "../pages/ViewApplication";
import EditParentCompany from "../pages/EditParentCompany";
import AssignParentCompany from "../pages/AssignParentCompany";
import CouponAdministrationHome from "../private-pages/CouponAdministration/CouponAdministration";
import NewMemberOnboardingHome from "../private-pages/NewMemberOnboarding/NewMemberOnboarding";
import ParentCompaniesHome from "../private-pages/ParentCompanies/ParentCompanies";
import CompanyRelationshipsHome from "../private-pages/CompanyRelationships/CompanyRelationships";
import CompaniesHome from "../private-pages/Companies/Companies";
import ViewCompromisedPrefixesHome from "../private-pages/ViewCompromisedPrefixes/ViewCompromisedPrefixes";
import CompromisedPrefixMatrixHome from "../private-pages/CompromisedPrefixMatrix/CompromisedPrefixMatrix";
import ActionMenuA1Home from "../private-pages/ActionMenuA1/ActionMenuA1";
import ActionMenuB2Home from "../private-pages/ActionMenuB2/ActionMenuB2";
import ActionMenuC3Home from "../private-pages/ActionMenuC3/ActionMenuC3";
import ExportGS1 from "../pages/SupplierProductsPage/ExportGS1";
import ExportGS1Detail from "../pages/SupplierProductsPage/ExportGS1Detail";
import ContactUsPage from "../private-pages/ContactSupport/ContactUs";

export default function Routes(props) {
  const isLoggedIn = useAuthContext().isUserLoggedIn;

  const redirectTo = (routeTo) => {
    return <Navigate to={"/" + routeTo} state={{ referer: props.location }} />;
  };

  return (
    <RoutesReactRouter>
      <Route
        path="/"
        element={
          isLoggedIn ? redirectTo("DashboardHome") : <NotLoggedInHomePage />
        }
      />
      <Route path="/investor" element={<InvestorInterest />} />
      <Route path="/TermsConditions" element={<TermsConditions />} />
      <Route path="/HowYouShopMatters" element={<HowYouShopMatters />} />

      <Route
        path="/SignUp"
        element={isLoggedIn ? redirectTo("DashboardHome") : <SignUpPage />}
      />
      <Route
        path="/SignIn"
        element={isLoggedIn ? redirectTo("DashboardHome") : <SignInPage />}
      />
      <Route
        path="/ForgotPassword"
        element={isLoggedIn ? redirectTo("DashboardHome") : <ForgotPassword />}
      />
      <Route
        path="/ResetPassword"
        element={isLoggedIn ? redirectTo("DashboardHome") : <ResetPassword />}
      />
      <Route
        path="/AppResetPassword"
        element={
          isLoggedIn ? redirectTo("DashboardHome") : <AppResetPassword />
        }
      />
      <Route path="/DownloadOurApp" element={<DownloadOurApp />} />
      <Route
        path="/UnderReview"
        element={<PrivateRoute element={<UnderReview />} />}
      />
      <Route
        path="*"
        element={isLoggedIn ? redirectTo("DashboardHome") : <PageNotFound />}
      />

      <Route path="/CompleteRegistration" element={<CompleteRegistration />} />
      <Route
        path="/CompleteSubscriptionRenewal"
        element={<CompleteSubscriptionRenewal />}
      />
      <Route
        path="/AppCompleteRegistration"
        element={<AppCompleteRegistration />}
      />

      <Route
        path="/GVDEditor"
        element={<PrivateRoute element={<GVDEditor />} />}
      />
      <Route
        path="/ValidateGVD"
        element={<PrivateRoute element={<ValidateGVD />} />}
      />
      <Route
        path="/ProductImport"
        element={<EmployeeRoute element={<ProductImport />} />}
      />
      <Route
        path="/SignupApproval"
        element={<EmployeeRoute element={<SignupApproval />} />}
      />
      <Route
        path="/EODReport"
        element={<EmployeeRoute element={<EODReport />} />}
      />
      <Route
        // path={/ProductsPage\/:segment(\d+)?\/:family(\d+)?\/:class(\d+)?\/:brick(\d+)?\/:grouping(\d+)?\/:variant(\d+)?\/:detail(\d+)?/}
        // need to make the regex above work but since react router v6 doesnt support regex anymore, it will be a challenge
        path="/ProductsPage/*"
        element={<PrivateRoute element={<ProductsPage />} />}
      />

      <Route
        path="/DashboardHome"
        element={<PrivateRoute element={<DashboardHome />} />}
      />
      <Route
        path="/Account-Details"
        element={<PrivateRoute element={<CompanyDetail />} />}
      />
      <Route
        path="/edit-product/:GTIN"
        element={<PrivateRoute element={<SupplierProduct />} />}
      />

      <Route
        path="/GetStarted/AccountDetails"
        element={<PrivateRoute element={<GetStartedAccountDetails />} />}
      />
      <Route
        path="/GetStarted/AccountPrefix"
        element={<PrivateRoute element={<GetStartedAccountPrefix />} />}
      />
      <Route
        path="/GetStarted/AccountPayment"
        element={<PrivateRoute element={<GetStartedAccountPayment />} />}
      />
      <Route
        path="/GetStarted/AccountReview"
        element={<PrivateRoute element={<GetStartedAccountReview />} />}
      />
      <Route
        path="/RenewSubscription"
        element={<PrivateRoute element={<RenewSubscription />} />}
      />
      <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
      <Route
        path="/productBrowser"
        element={<PrivateRoute element={<Profile />} />}
      />

      <Route
        path="/GetStarted/AccountContactDetails"
        element={<PrivateRoute element={<GetStartedAccountContactDetails />} />}
      />
      <Route
        path="/GetStarted/AccountTypeSelection"
        element={<PrivateRoute element={<GetStartedAccountTypeSelection />} />}
      />
      <Route
        path="/GetStarted/AccountTypeSupplier"
        element={<PrivateRoute element={<GetStartedAccountTypeSupplier />} />}
      />
      <Route
        path="/GetStarted/AccountTypeSupplierConfirmation"
        element={
          <PrivateRoute
            element={<GetStartedAccountTypeSupplierConfirmation />}
          />
        }
      />
      <Route
        path="/GetStarted/AccountTypeRetailer"
        element={<PrivateRoute element={<GetStartedAccountTypeRetailer />} />}
      />
      <Route
        path="/GetStarted/AccountTypeServiceProvider"
        element={
          <PrivateRoute element={<GetStartedAccountTypeServiceProvider />} />
        }
      />
      <Route
        path="/GetStarted/AccountWaitingApproval"
        element={
          <PrivateRoute element={<GetStartedAccountWaitingApproval />} />
        }
      />
      <Route
        path="/GetStarted/IEI"
        element={
          <PrivateRoute element={<GetStartedInvestorExpressionOfInterest />} />
        }
      />

      <Route
        path="/SignupHome"
        element={<PrivateRoute element={<SignupHome />} />}
      />
      <Route
        path="/Signup/SignupIntro"
        element={<PrivateRoute element={<SignupIntro />} />}
      />
      <Route
        path="/Signup/SignupType"
        element={<PrivateRoute element={<SignupType />} />}
      />
      <Route
        path="/Signup/SignupSubscription"
        element={<PrivateRoute element={<SignupSubscription />} />}
      />
      <Route
        path="/Signup/SignupUsers"
        element={<PrivateRoute element={<SignupUsers />} />}
      />

      <Route
        path="/EntityHome"
        element={<PrivateRoute element={<EntityHome />} />}
      />
      <Route
        path="/Entity/EntityUsers"
        element={<PrivateRoute element={<EntityUsers />} />}
      />
      <Route
        path="/Entity/EntitySubscription"
        element={<PrivateRoute element={<EntitySubscription />} />}
      />
      <Route
        path="/Entity/EntityLoyalty"
        element={<PrivateRoute element={<EntityLoyalty />} />}
      />

      <Route
        path="/AccountHome"
        element={<PrivateRoute element={<AccountHome />} />}
      />
      <Route
        path="/Account/AccountDetails"
        element={<PrivateRoute element={<AccountDetails />} />}
      />
      <Route
        path="/Account/AccountTransactions"
        element={<PrivateRoute element={<AccountTransactions />} />}
      />

      <Route
        path="/SupplierHome"
        element={<PrivateRoute element={<SupplierHome />} />}
      />
      <Route
        path="/Supplier/SupplierAnalytics"
        element={<PrivateRoute element={<SupplierAnalytics />} />}
      />
      <Route
        path="/Supplier/SupplierAdvertising"
        element={<PrivateRoute element={<SupplierAdvertising />} />}
      />
      <Route
        path="/Supplier/SupplierLocations"
        element={<PrivateRoute element={<SupplierLocations />} />}
      />
      <Route
        path="/Supplier/SupplierOverrides"
        element={<PrivateRoute element={<SupplierOverrides />} />}
      />
      <Route
        path="/Supplier/SupplierPrefixes"
        element={<PrivateRoute element={<SupplierPrefixes />} />}
      />
      <Route
        path="/Supplier/SupplierProducts"
        element={<SupplierRoute element={<MyProducts />} />}
      />

      <Route
        path="/RetailerHome"
        element={<PrivateRoute element={<RetailerHome />} />}
      />
      <Route
        path="/Retailer/RetailerAdvertising"
        element={<PrivateRoute element={<RetailerAdvertising />} />}
      />
      <Route
        path="/Retailer/RetailerAnalytics"
        element={<PrivateRoute element={<RetailerAnalytics />} />}
      />
      <Route
        path="/Retailer/RetailerProducts"
        element={<PrivateRoute element={<RetailerProducts />} />}
      />
      <Route
        path="/Retailer/RetailerStores"
        element={<PrivateRoute element={<RetailerStores />} />}
      />

      <Route
        path="/ServiceHome"
        element={<PrivateRoute element={<ServiceHome />} />}
      />
      <Route
        path="/Service/ServiceAdvertising"
        element={<PrivateRoute element={<ServiceAdvertising />} />}
      />
      <Route
        path="/Service/ServiceAnalytics"
        element={<PrivateRoute element={<ServiceAnalytics />} />}
      />
      <Route
        path="/Service/ServiceStores"
        element={<PrivateRoute element={<ServiceStores />} />}
      />
      <Route
        path="/Service/ServiceServices"
        element={<PrivateRoute element={<ServiceServices />} />}
      />

      <Route
        path="/ProductHome"
        element={<PrivateRoute element={<ProductHome />} />}
      />
      <Route
        path="/productscan"
        element={<PrivateRoute element={<ProductScanNew />} />}
      />
      {/* <Route
        path="/Product/ProductScan"
        element={<PrivateRoute element={<ProductScan />} />}
      /> */}
      <Route
        path="/Product/ProductBrands"
        element={<PrivateRoute element={<ProductBrands />} />}
      />
      <Route
        path="/Product/ProductBricks"
        element={<PrivateRoute element={<ProductBricks />} />}
      />
      <Route
        path="/Product/ProductImages"
        element={<PrivateRoute element={<ProductImages />} />}
      />

      <Route
        path="/LoyaltyHome"
        element={<PrivateRoute element={<LoyaltyHome />} />}
      />
      <Route
        path="/Loyalty/LoyaltyProgram"
        element={<PrivateRoute element={<LoyaltyProgram />} />}
      />
      <Route
        path="/Loyalty/LoyaltyPartners"
        element={<PrivateRoute element={<LoyaltyPartners />} />}
      />
      <Route
        path="/Loyalty/Technical"
        element={<PrivateRoute element={<LoyaltyTechnicalHome />} />}
      />
      <Route
        path="/Loyalty/LoyaltyEndpoints"
        element={<PrivateRoute element={<LoyaltyEndpoints />} />}
      />
      <Route
        path="/Loyalty/LoyaltyImplementations"
        element={<PrivateRoute element={<LoyaltyImplementations />} />}
      />
      <Route
        path="/Loyalty/LoyaltyIntegration"
        element={<PrivateRoute element={<LoyaltyIntegration />} />}
      />

      <Route
        path="/DatabaseHome"
        element={<PrivateRoute element={<DatabaseHome />} />}
      />
      <Route
        path="/Database/DatabaseBrowser"
        element={<PrivateRoute element={<DatabaseBrowser />} />}
      />
      <Route
        path="/Database/DatabaseAPI"
        element={<PrivateRoute element={<DatabaseAPI />} />}
      />

      <Route
        path="/DatabaseUpdateHome"
        element={<PrivateRoute element={<DatabaseUpdateHome />} />}
      />
      <Route
        path="/DatabaseUpdate/GS1Global"
        element={<PrivateRoute element={<GS1Global />} />}
      />
      <Route
        path="/DatabaseUpdate/GS1AUS"
        element={<PrivateRoute element={<GS1AUS />} />}
      />
      <Route
        path="/DatabaseUpdate/GS1USA"
        element={<PrivateRoute element={<GS1USA />} />}
      />

      <Route
        path="/SupportHome"
        element={<PrivateRoute element={<SupportHome />} />}
      />

      <Route
        path="/Support/GS1/GS1Home"
        element={<PrivateRoute element={<SupportGS1Home />} />}
      />
      <Route
        path="/Support/GS1/GS1GPC"
        element={<PrivateRoute element={<SupportGS1GPC />} />}
      />
      <Route
        path="/Support/GS1/GS12"
        element={<PrivateRoute element={<SupportGS12 />} />}
      />
      <Route
        path="/Support/GS1/GS13"
        element={<PrivateRoute element={<SupportGS13 />} />}
      />

      <Route
        path="/Support/GS1AUS/GS1AUSHome"
        element={<PrivateRoute element={<SupportGS1AUS />} />}
      />
      <Route
        path="/Support/GS1AUS/GS1AUSImages"
        element={<PrivateRoute element={<SupportGS1AUSImages />} />}
      />
      <Route
        path="/Support/GS1AUS/GS1AUS2"
        element={<PrivateRoute element={<SupportGS1AUS2 />} />}
      />
      <Route
        path="/Support/GS1AUS/GS1AUS3"
        element={<PrivateRoute element={<SupportGS1AUS3 />} />}
      />

      <Route
        path="/Support/GS1USA/GS1USAHome"
        element={<PrivateRoute element={<SupportGS1USA />} />}
      />
      <Route
        path="/Support/GS1USA/GS1USAImages"
        element={<PrivateRoute element={<SupportGS1USAImages />} />}
      />
      <Route
        path="/Support/GS1USA/GS1USA2"
        element={<PrivateRoute element={<SupportGS1USA2 />} />}
      />
      <Route
        path="/Support/GS1USA/GS1USA3"
        element={<PrivateRoute element={<SupportGS1USA3 />} />}
      />

      <Route
        path="/TestingHome"
        element={<PrivateRoute element={<TestingHome />} />}
      />
      <Route
        path="/Testing/TestingSearch"
        element={<PrivateRoute element={<TestingSearch />} />}
      />

      <Route
        path="/UserHome"
        element={<PrivateRoute element={<UserHome />} />}
      />
      <Route
        path="/User/UserProfile"
        element={<PrivateRoute element={<UserProfile />} />}
      />

      <Route
        path="/SignUpApprovalTab/:TabName"
        element={<PrivateRoute element={<SignUpApprovalTab />} />}
      />
      <Route
        path="/view-application/:SignupID"
        element={<PrivateRoute element={<ViewApplication />} />}
      />
      <Route
        path="/edit-parent-company"
        element={<PrivateRoute element={<EditParentCompany />} />}
      />
      <Route
        path="/assign-company"
        element={<PrivateRoute element={<AssignParentCompany />} />}
      />

      <Route element={isLoggedIn ? NotFoundPage : redirectTo("SignIn")} />

      <Route
        path="/ManageBrands"
        element={<PrivateRoute element={<ManageBrands />} />}
      />
      <Route
        path="/CouponAdministration"
        element={<PrivateRoute element={<CouponAdministrationHome />} />}
      />
      <Route
        path="/NewMemberOnboarding"
        element={<PrivateRoute element={<NewMemberOnboardingHome />} />}
      />
      <Route
        path="/ParentCompanies"
        element={<PrivateRoute element={<ParentCompaniesHome />} />}
      />
      <Route
        path="/Companies"
        element={<PrivateRoute element={<CompaniesHome />} />}
      />
      <Route
        path="/CompanyRelationships"
        element={<PrivateRoute element={<CompanyRelationshipsHome />} />}
      />
      <Route
        path="/ViewCompromisedPrefixes"
        element={<PrivateRoute element={<ViewCompromisedPrefixesHome />} />}
      />
      <Route
        path="/CompromisedPrefixMatrix"
        element={<PrivateRoute element={<CompromisedPrefixMatrixHome />} />}
      />
      <Route
        path="/ActionMenuA1"
        element={<PrivateRoute element={<ActionMenuA1Home />} />}
      />
      <Route
        path="/ActionMenuB2"
        element={<PrivateRoute element={<ActionMenuB2Home />} />}
      />
      <Route
        path="/ActionMenuC3"
        element={<PrivateRoute element={<ActionMenuC3Home />} />}
      />
      <Route
        path="/ContactUs"
        element={<PrivateRoute element={<ContactUsPage />} />}
      />
      <Route
        path="/ExportGS1"
        element={<PrivateRoute element={<ExportGS1 />} />}
      />
      <Route
        path="/gs1-details/:GTIN"
        element={<PrivateRoute element={<ExportGS1Detail />} />}
      />
    </RoutesReactRouter>
  );
}
