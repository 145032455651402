import DashboardContainer from "../../dashboard-components/DashboardContainer";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import ReactDataGrid, { filterTypes } from "@inovua/reactdatagrid-enterprise";
import { HTTP_STATUS_CODES } from "../../api/API";
import {
  fetchAttributes,
  fetchBrick,
  fetchClass,
  fetchClassification,
  fetchData,
  fetchDetail,
  fetchFamily,
  fetchGrouping,
  fetchProductAttributes,
  fetchSegmentInfo,
  fetchVariant,
  getProductBrands,
  productsPage,
  fetchAttributesValues,
  setActive,
  setDataSource,
  setFilterValues,
  setLimit,
  setPage,
} from "../../store/slices/productsPageSlice";
import "@inovua/reactdatagrid-enterprise/index.css";
import defaultColumns from "./productGridColumns";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Tooltip from "../../components/tooltip";
import AssignBrandDialog from "../../components/Dialogs/AssignBrandDialog";
import AssignProductCategory from "../../components/Dialogs/AssignProductCategory";
import AssignAttributes from "../../components/Dialogs/AssignAttributes";
import AddProduct from "./AddProduct";
import { setError } from "../../store/slices/brandSlice";
import Select from "react-select";
import {
  fetchSubmit,
  submitProductAttributes,
} from "../../store/slices/productSlice";
import { Alert } from "@mui/material";
import { getUser } from "../../provider/AuthProvider";

const ProductsPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userAuth = new useAuthContext();
  const userparentCompanies = userAuth.parentCompanies;
  const { active, dataSource, page, limit, filterValues } =
    useAppSelector(productsPage);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const [entityGln, setEntityGln] = useState(true);
  const [brick, setBrick] = useState(true);
  const [gtinValue, setGtinValue] = useState("");
  const [isOpenCategories, setIsOpenCategories] = useState(false);
  const [isOpenAttributes, setIsOpenAttributes] = useState(false);
  const [gridRef, setGridRef] = useState(null);
  const [assignBrandDialog, setAssignBrandDialog] = useState(false);
  const [assignProductCategory, setAssignProductCategory] = useState(false);
  const [assignAttributes, setAssignAttributes] = useState(false);
  const [selected, setSelected] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [productBrickId, setProductBrickId] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState("");
  const [segmentData, setSegmentData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [familyData, setFamilyData] = useState([]);
  const [brickData, setBrickData] = useState([]);
  const [groupingData, setGroupingData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [detailData, setDetailData] = useState([]);

  const [attributeData, setAttributeData] = useState([]);

  const [categoryForm, setCategoryForm] = useState({
    FK_SegmentID: "",
    FK_FamilyID: "",
    FK_ClassID: "",
    FK_BrickID: "",
    FK_GroupingID: "",
    FK_VariantID: "",
    FK_DetailID: "",
  });

  // UseEffect to handle showing and hiding success and error messages
  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess("");
      }, 2000);
    }

    if (showError) {
      setTimeout(() => {
        setShowError("");
      }, 2000);
    }
  }, [showSuccess, showError]);

  // Handle filter value change with debouncing
  const handleFilterValueChange = (o) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Dispatch your action after the timeout
      dispatch(setFilterValues(o));
    }, 2000); // Adjust the timeout value as needed (2 second in this example)

    // Save the new timeout ID in the state
    setTimeoutId(newTimeoutId);
  };

  // UseEffect to load data based on dependencies
  useEffect(() => {
    loadData();
  }, [active, page, limit, filterValues]);

  // Function to load data
  const loadData = () => {
    const fetchApiData = async ({ ParentCompanyID, filter, page, limit }) => {
      setLoading(true);
      const res = await dispatch(
        fetchData(ParentCompanyID, filter, page, limit)
      );

      if (res.status !== HTTP_STATUS_CODES.OK) {
        setLoading(false);
        return Promise.resolve({ data: [], count: 0 });
      }
      setLoading(false);
      return Promise.resolve({
        data: res.data.data,
        count: res.data.total,
      });
    };

    dispatch(
      setDataSource(
        fetchApiData({
          ParentCompanyID: userparentCompanies?.ParentCompanyID,
          active: active,
          filter: filterValues,
          page: page,
          limit: limit,
        })
      )
    );
  };

  // Handle selection change
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);

    let setBrickStatus = true;
    let setEntityGlnStatus = true;
    let productIDArray = [];
    if (Object.keys(selected)?.length !== 0) {
      for (let i = 0; i < Object.keys(selected).length; i++) {
        productIDArray.push(Object.values(selected)[i].ProductID);
        if (
          (i > 0 &&
            Object.values(selected)[i - 1].EntityGLN !==
              Object.values(selected)[i].EntityGLN) ||
          (i > 0 &&
            Object.values(selected)[i - 1].CompanyID !==
              Object.values(selected)[i].CompanyID)
        ) {
          setEntityGlnStatus = false;
        }

        if (
          i > 0 &&
          Object.values(selected)[i - 1].BrickID !==
            Object.values(selected)[i].BrickID
        ) {
          setBrickStatus = false;
        }
      }
      setGtinValue(Object.values(selected)[0].GTIN);
      setCompanyId(Object.values(selected)[0].CompanyID);
    }
    setProductIds(productIDArray);
    setBrick(setBrickStatus);
    setEntityGln(setEntityGlnStatus);
  }, []);

  // Function to open brand modal
  const openBrandModal = async (record = null) => {
    let FK_CompanyID = 0;
    if (Object.keys(selected)?.length === 0) {
      FK_CompanyID = record?.CompanyID;
      let productIDArray = [];
      productIDArray.push(record?.ProductID);
      setProductIds(productIDArray);
    } else {
      FK_CompanyID = companyId;
    }

    setIsOpenBrand(true);
    const brands = await getProductBrands(FK_CompanyID, "1");
    if (brands.length) {
      const mappedOptions = brands.map((brand) => ({
        value: brand.BrandID,
        label: `${brand.Brand}${brand.BrandSub ? ` - ${brand.BrandSub}` : ""}`,
      }));
      // Update options state

      setOptions(mappedOptions);
    }
  };

  // Function to save brands
  const saveBrands = async () => {
    closeBrandModal();
    for (let i = 0; i < productIds.length; i++) {
      let data = {
        ProductID: productIds[i],
        FK_BrandID: selectedOption.value,
      };
      await dispatch(fetchSubmit(data));
    }
    loadData();
  };

  // Handle change function for selecting options
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Function to close brand modal
  const closeBrandModal = () => {
    setIsOpenBrand(false);
    setTimeout(() => {
      dispatch(setError(""));
    }, 1000);
  };

  // Function to open categories modal
  const openCategoriesModal = async () => {
    setIsOpenCategories(true);
    const segments = await dispatch(fetchSegmentInfo());
    setSegmentData(segments);
  };

  // Function to close categories modal
  const closeCategoriesModal = () => {
    setIsOpenCategories(false);
    clearCategoryForm();
    setTimeout(() => {
      dispatch(setError(""));
    }, 1000);
  };

  // Function to clear the category form data
  const clearCategoryForm = () => {
    setCategoryForm({
      FK_SegmentID: "",
      FK_FamilyID: "",
      FK_ClassID: "",
      FK_BrickID: "",
      FK_GroupingID: "",
      FK_VariantID: "",
      FK_DetailID: "",
    });
    setFamilyData([]);
    setClassData([]);
    setBrickData([]);
    setGroupingData([]);
    setVariantData([]);
    setDetailData([]);
  };

  // Handle category fields change
  const handleCategoryFields = async (event) => {
    const { name, value } = event.target;
    setCategoryForm((prevState) => ({ ...prevState, [name]: value }));

    // if (name === "FK_SegmentID") {
    // 	const familyRecord = await dispatch(fetchFamily(value));
    // 	setFamilyData(familyRecord);
    // }else if (name === "FK_FamilyID") {
    // 	const classRecord = await dispatch(fetchClass(value));
    // 	setClassData(classRecord);
    // }else if (name === "FK_ClassID") {
    // 	const brickRecord = await dispatch(fetchBrick(parseInt(value)));
    // 	setBrickData(brickRecord);
    // }else if (name === "FK_BrickID") {
    // 	const groupRecord = await dispatch(fetchGrouping(value));
    // 	setGroupingData(groupRecord);
    // }else if (name === "FK_GroupingID") {
    // 	const variantRecord = await dispatch(fetchVariant(value));
    // 	setVariantData(variantRecord)
    // }else if (name === "FK_VariantID") {
    // 	const detailRecord = await dispatch(fetchDetail(value));
    // 	setDetailData(detailRecord);
    // }
    switch (name) {
      case "FK_SegmentID":
        const familyRecord = await dispatch(fetchFamily(value));
        setFamilyData(familyRecord);
        setClassData([]);
        setBrickData([]);
        setGroupingData([]);
        setVariantData([]);
        setDetailData([]);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_FamilyID: "",
          FK_ClassID: "",
          FK_BrickID: "",
          FK_GroupingID: "",
          FK_VariantID: "",
          FK_DetailID: "",
        }));
        break;
      case "FK_FamilyID":
        const classRecord = await dispatch(fetchClass(value));
        setClassData(classRecord);
        setBrickData([]);
        setGroupingData([]);
        setVariantData([]);
        setDetailData([]);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_ClassID: "",
          FK_BrickID: "",
          FK_GroupingID: "",
          FK_VariantID: "",
          FK_DetailID: "",
        }));
        break;
      case "FK_ClassID":
        const brickRecord = await dispatch(fetchBrick(parseInt(value)));
        setBrickData(brickRecord);
        setGroupingData([]);
        setVariantData([]);
        setDetailData([]);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_BrickID: "",
          FK_GroupingID: "",
          FK_VariantID: "",
          FK_DetailID: "",
        }));
        break;
      case "FK_BrickID":
        const groupRecord = await dispatch(fetchGrouping(value));
        setGroupingData(groupRecord);
        setVariantData([]);
        setDetailData([]);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_GroupingID: "",
          FK_VariantID: "",
          FK_DetailID: "",
        }));
        break;
      case "FK_GroupingID":
        const variantRecord = await dispatch(fetchVariant(value));
        setVariantData(variantRecord);
        setDetailData([]);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_VariantID: "",
          FK_DetailID: "",
        }));
        break;
      case "FK_VariantID":
        const detailRecord = await dispatch(fetchDetail(value));
        setDetailData(detailRecord);
        setCategoryForm((prevState) => ({
          ...prevState,
          FK_DetailID: "",
        }));
        break;
      default:
        break;
    }
  };

  // Function to save categories
  const saveCategories = async () => {
    if (
      categoryForm.FK_SegmentID === "" ||
      categoryForm.FK_FamilyID === "" ||
      categoryForm.FK_ClassID === "" ||
      categoryForm.FK_BrickID === ""
    ) {
      setShowError("Required Fields are missing");
      return;
    }
    const detailID = await getClassification();
    closeCategoriesModal();
    for (let i = 0; i < productIds.length; i++) {
      let data = {
        ProductID: productIds[i],
        FK_Detail: detailID.DetailID,
      };
      await dispatch(fetchSubmit(data));
    }
    loadData();
  };

  // Function to get classification
  const getClassification = async () => {
    const data = {
      SegmentID: categoryForm.FK_SegmentID,
      FamilyID: categoryForm.FK_FamilyID,
      ClassID: categoryForm.FK_ClassID,
      BrickID: categoryForm.FK_BrickID,
      GroupingID: categoryForm.FK_GroupingID,
      VariantID: categoryForm.FK_VariantID,
    };
    return await dispatch(fetchClassification(data));
  };

  // Function to open attributes modal
  const openAttributesModal = async (record = null) => {
    let brickId = 0;
    let productIDArray = [];
    setIsOpenAttributes(true);

    if (Object.keys(selected)?.length === 0) {
      brickId = record?.BrickID;
      let productId = record?.ProductID;
      productIDArray.push(record?.ProductID);
      setProductIds(productIDArray);
      setProductBrickId(brickId);
    } else {
      brickId = Object.values(selected)[0].BrickID;
      productIDArray = productIds;
      setProductBrickId(brickId);
    }
    const resultData = await dispatch(fetchAttributes(brickId));
    let productAttributeArray = [];
    for (let i = 0; i < productIDArray.length; i++) {
      productAttributeArray[productIDArray[i]] = await fetchProductAttributes(
        productIDArray[i]
      );
    }

    for (let i = 0; i < productIDArray.length; i++) {
      const selectedAttributes = productAttributeArray[productIDArray[i]];
      for (const attribute of resultData) {
        attribute.attributeValues = [];
        attribute.selectedAttribute = "";
        attribute.oldSelectedAttribute = "";
        attribute.preValue = true;
        for (let i = 0; i < selectedAttributes.length; ++i) {
          if (
            selectedAttributes[i].AttributeTypeID === attribute.AttributeTypeID
          ) {
            attribute.selectedAttribute = {
              BrickAttributeID: selectedAttributes[i].BrickAttributeID,
              AttributeValueDesc: selectedAttributes[i].AttributeValueDesc,
            };
            attribute.oldSelectedAttribute =
              selectedAttributes[i].BrickAttributeID;
            attribute.attributeValues.push({
              BrickAttributeID: selectedAttributes[i].BrickAttributeID,
              AttributeValueDesc: selectedAttributes[i].AttributeValueDesc,
            });
            break;
          }
        }
      }
    }
    setAttributeData(resultData);
  };

  const getAttributeValues = async (index) => {
    if (attributeData[index].preValue) {
      const resultData = await dispatch(
        fetchAttributesValues(productBrickId, attributeData, index)
      );
      setAttributeData(resultData);
    }
  };
  // Handle attribute change function
  const handleAttributeChange = (obj, value) => {
    let data = JSON.parse(JSON.stringify(attributeData));
    const findIndex = data.findIndex((x) => x.AttributeTypeID === value.name);
    if (findIndex > -1) {
      data[findIndex].selectedAttribute = obj;
    }

    setAttributeData(data);
  };
  // Save attribute function
  const saveProductAttributes = async () => {
    let data = JSON.parse(JSON.stringify(attributeData));
    for (let i = 0; i < productIds.length; i++) {
      for (let i = 0; i < data.length; ++i) {
        if (
          typeof data[i].selectedAttribute?.BrickAttributeID !== "undefined" &&
          data[i].selectedAttribute?.BrickAttributeID !==
            data[i].oldSelectedAttribute
        ) {
          const updateAttributeValues = {
            OLD_BrickAttributeID: data[i].oldSelectedAttribute,
            NEW_BrickAttributeID: data[i].selectedAttribute.BrickAttributeID,
            ProductID: productIds[i],
            AuditUserID: getUser().UserID,
          };
          await dispatch(submitProductAttributes(updateAttributeValues));
        }
      }
    }
    closeAttributesModal();
    loadData();
  };

  // Function to close attribute modal
  const closeAttributesModal = () => {
    setIsOpenAttributes(false);
    setTimeout(() => {
      dispatch(setError(""));
    }, 1000);
  };

  const SelectDropdown = ({
    label,
    name,
    options,
    selectedValue,
    onChange,
    isRequired,
    isDisabled,
  }) => (
    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
      <h6 className="w-full max-w-[200px] flex justify-end">
        {isRequired && <span className="text-red-500">*</span>}
        {label}
      </h6>
      <select
        name={name}
        onChange={onChange}
        className={`rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm ${
          isDisabled ? "bg-gray-200 cursor-not-allowed" : ""
        }`}
        value={selectedValue}
        disabled={isDisabled}
      >
        <option value="">--select--</option>
        {options.length
          ? options.map((data) => (
              <option
                className="font-bold"
                value={data.ID}
                key={data.ID}
                selected={selectedValue === data.ID}
              >
                {data?.Desc === null ? "None" : data.Desc}
              </option>
            ))
          : null}
      </select>
    </div>
  );
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "10px",
        height: "0px",
      },
    }),
  };
  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    const { GTIN } = rowProps.data;
    const isEditAllowed = rowProps.data.length === 1;
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: (
          <div className="group inline-block group1 inline-block1">
            <ul className="bg-white rounded-sm origin-top min-w-32 ul border">
              <li className="rounded-sm relative px-3 py-1 primary li border">
                <button className="w-full text-left flex items-center outline-none focus:outline-none button text-left">
                  <span className="pr-1 flex-1">Batch Updates</span>
                  <span className="mr-auto">
                    <svg
                      className="fill-current h-4 w-4 transition duration-150 ease-in-out svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className="bg-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32 ul z-index-1">
                  {entityGln ? (
                    <li
                      className="px-3 py-1 primary li border"
                      onClick={() => {
                        openBrandModal(rowProps.data);
                      }}
                    >
                      Brand
                    </li>
                  ) : (
                    <li className="px-3 py-1 li border text-gray-400 cursor-not-allowed">
                      Brand
                    </li>
                  )}
                  <li
                    className="px-3 py-1 primary li border"
                    onClick={() => {
                      openCategoriesModal();
                    }}
                  >
                    Categories
                  </li>
                  {brick ? (
                    <li
                      className="px-3 py-1 primary hover:bg-gray-100 li border"
                      onClick={() => {
                        openAttributesModal(rowProps.data);
                      }}
                    >
                      Attributes
                    </li>
                  ) : (
                    <li className="px-3 py-1 li border text-gray-400 cursor-not-allowed">
                      Attributes
                    </li>
                  )}
                </ul>
              </li>
              {/* <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
								<Link to={`/edit-product/${GTIN}`} className="w-full text-left">
									<button style={{cursor: 'pointer'}} className="w-full text-left">Edit Details
									</button>
								</Link>
							</li> */}
              {brick ? (
                <li className="rounded-sm px-3 py-1 hover:bg-gray-100 li border">
                  <Link
                    to={`/edit-product/${GTIN}`}
                    className="w-full text-left"
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      className="w-full text-left"
                    >
                      Edit Details
                    </button>
                  </Link>
                </li>
              ) : (
                <li className="rounded-sm px-3 py-1 li border text-gray-400 cursor-not-allowed">
                  Edit Details
                </li>
              )}
            </ul>
          </div>
        ),
      },
    ];
  };
  return (
    <>
      <div className="w-full pt-2 flex-row justify-end space-x-1 text-right">
        <AddProduct />
      </div>
      <DashboardContainer title={""}>
        <div className="">
          <div className="flex justify-start gap-3 items-center rounded bg-primary pt-3 px-2">
            <button
              onClick={() => dispatch(setActive(1))}
              className={`${
                active === 1 ? "bg-white text-black" : "text-white"
              } uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
            >
              <Tooltip
                trigger={
                  <p className="flex items-center gap-2 ">
                    gs1
                    <p className="w-4 h-4 lowercase flex justify-center items-center bg-black text-white rounded-full">
                      i
                    </p>
                  </p>
                }
                placement={"left"}
                tooltipText={"This page shows your Product Data (GS1)"}
              />
            </button>

            <button
              onClick={() => dispatch(setActive(2))}
              className={`${
                active === 2 ? "bg-white text-black" : "text-white"
              } uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
            >
              <Tooltip
                trigger={
                  <p className="flex items-center gap-2">
                    Categorised{" "}
                    <p className="w-4 lowercase flex justify-center items-center h-4 bg-black text-white rounded-full">
                      i
                    </p>
                  </p>
                }
                placement={"left"}
                tooltipText={"This page shows your Product Data (Categorised)"}
              />
            </button>

            <button
              onClick={() => dispatch(setActive(3))}
              className={`${
                active === 3 ? "bg-white text-black" : "text-white"
              } uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
            >
              <Tooltip
                trigger={
                  <p className="flex items-center gap-2">
                    Non-Categorised{" "}
                    <p className="w-4 lowercase flex justify-center items-center h-4 bg-black text-white rounded-full">
                      i
                    </p>
                  </p>
                }
                placement={"left"}
                tooltipText={
                  "This page shows your Product Data (Non-Categorised)"
                }
              />
            </button>
          </div>
          <div className="bg-white rounded-t-none rounded-b">
            {[1, 2, 3].map((val) => {
              return val === active ? (
                <ReactDataGrid
                  key={val} // Ensure each component has a unique key
                  licenseKey={
                    "AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2025-02-28T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=475673346-1475987186384940901-208737334117291946891833040988"
                  }
                  onReady={setGridRef}
                  idProperty="ProductID"
                  style={{ marginTop: 10, minHeight: "90vh" }}
                  columns={defaultColumns}
                  checkboxColumn
                  onSelectionChange={onSelectionChange}
                  defaultFilterValue={filterValues}
                  filterTypes={filterTypes}
                  rowHeight={90}
                  renderRowContextMenu={renderRowContextMenu}
                  pagination="remote"
                  onFilterValueChange={(o) => handleFilterValueChange(o)}
                  dataSource={dataSource}
                  onSkipChange={(o) =>
                    dispatch(
                      setPage(Math.round(Math.max(o - 1, 0) / limit) + 1)
                    )
                  }
                  onLimitChange={(o) => dispatch(setLimit(o))}
                  defaultLimit={10}
                />
              ) : null;
            })}
          </div>
        </div>

        <AssignBrandDialog
          setAssignBrandDialog={setAssignBrandDialog}
          assignBrandDialog={assignBrandDialog}
        />
        <AssignProductCategory
          setAssignProductCategory={setAssignProductCategory}
          assignProductCategory={assignProductCategory}
        />
        <AssignAttributes
          setAssignAttributes={setAssignAttributes}
          assignAttributes={assignAttributes}
        />
      </DashboardContainer>
      {isOpenBrand ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ minWidth: "48rem" }}
            >
              {/*content*/}
              <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Select Brand from Drop-Down List
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeBrandModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2">
                    <h6 className="w-full max-w-[120px] flex justify-end">
                      Search Brands:
                    </h6>
                    <Select
                      className="rounded-md w-full font-bold style shadow-sm sm:text-sm"
                      styles={styles}
                      placeholder={"-- Select Brand From List --"}
                      name="brand"
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b gap-5">
                  <button
                    className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
                    type="button"
                    onClick={closeBrandModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primary py-2 w-24 rounded-lg text-white"
                    type="button"
                    onClick={saveBrands}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {isOpenCategories ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ minWidth: "48rem" }}
            >
              {/*content*/}
              <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex align-items-center justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Assign Product Category:
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeCategoriesModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                <div
                  className={
                    "justify-center w-full flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3"
                  }
                >
                  {showError !== "" && (
                    <Alert severity="error">{showError}</Alert>
                  )}
                </div>
                <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
                  <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
                    <p className="font-semibold text-base">
                      Global Product Classification (GPC)
                    </p>
                  </div>
                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Segment:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_SegmentID"
                    options={segmentData || []}
                    selectedValue={categoryForm.FK_SegmentID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={false}
                  />

                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Family:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_FamilyID"
                    options={familyData || []}
                    selectedValue={categoryForm.FK_FamilyID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={categoryForm.FK_SegmentID === ""}
                  />

                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Class:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_ClassID"
                    options={classData || []}
                    selectedValue={categoryForm.FK_ClassID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === ""
                    }
                  />

                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Brick:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_BrickID"
                    options={brickData || []}
                    selectedValue={categoryForm.FK_BrickID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === ""
                    }
                  />
                  <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
                    <p className="font-semibold text-base">
                      Grouping Variant Detail (GVD)
                    </p>
                  </div>
                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Grouping:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_GroupingID"
                    options={groupingData || []}
                    selectedValue={categoryForm.FK_GroupingID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === "" ||
                      categoryForm.FK_BrickID === ""
                    }
                  />

                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Variant:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_VariantID"
                    options={variantData || []}
                    selectedValue={categoryForm.FK_VariantID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === "" ||
                      categoryForm.FK_BrickID === "" ||
                      categoryForm.FK_GroupingID === ""
                    }
                  />

                  <SelectDropdown
                    label={
                      <span className="w-full max-w-[200px] flex justify-end">
                        Detail:&nbsp;
                        <Tooltip
                          trigger={
                            <span
                              className="w-4 h-4 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                            >
                              i
                            </span>
                          }
                          placement={"left"}
                          tooltipText=""
                        />
                      </span>
                    }
                    name="FK_DetailID"
                    options={detailData || []}
                    selectedValue={categoryForm.FK_DetailID}
                    onChange={handleCategoryFields}
                    isRequired={false}
                    isDisabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === "" ||
                      categoryForm.FK_BrickID === "" ||
                      categoryForm.FK_GroupingID === "" ||
                      categoryForm.FK_VariantID === ""
                    }
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b gap-5">
                  <button
                    className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
                    type="button"
                    onClick={closeCategoriesModal}
                  >
                    Cancel
                  </button>
                  <button
                    className={`bg-primary py-2 w-24 rounded-lg text-white ${
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === "" ||
                      categoryForm.FK_BrickID === ""
                        ? "disabled:opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    type="button"
                    onClick={saveCategories}
                    disabled={
                      categoryForm.FK_SegmentID === "" ||
                      categoryForm.FK_FamilyID === "" ||
                      categoryForm.FK_ClassID === "" ||
                      categoryForm.FK_BrickID === ""
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
      {isOpenAttributes ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl"
              style={{ minWidth: "48rem" }}
            >
              {/*content*/}
              <div className=" rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Select Attributes Dialogue Box
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeAttributesModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  {attributeData.length
                    ? attributeData.map((attribute, index) => (
                        <div
                          className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3 p-2"
                          key={attribute.AttributeTypeID}
                        >
                          <h6 className="w-full max-w-[200px] flex justify-end text-right">
                            {attribute.AttributeTypeDesc}:
                            <Tooltip
                              trigger={
                                <span
                                  className="w-4 h-4 ml-1 lowercase flex justify-center items-center
                            bg-gray-400 text-white rounded-full"
                                >
                                  i
                                </span>
                              }
                              placement={"left"}
                              tooltipText=""
                            />
                          </h6>
                          <Select
                            className="rounded-md w-full font-bold style shadow-sm sm:text-sm"
                            styles={styles}
                            placeholder={"-- Select From List --"}
                            name={attribute.AttributeTypeID}
                            getOptionLabel={(option) =>
                              option.AttributeValueDesc
                            }
                            getOptionValue={(option) => option.BrickAttributeID}
                            onChange={handleAttributeChange}
                            onFocus={() => getAttributeValues(index)}
                            value={attribute.selectedAttribute}
                            options={attribute?.attributeValues}
                          />
                        </div>
                      ))
                    : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b gap-5">
                  <button
                    className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
                    type="button"
                    onClick={closeAttributesModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primary py-2 w-24 rounded-lg text-white"
                    type="button"
                    onClick={saveProductAttributes}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ProductsPage;
