import React, { Fragment, useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { setProcessing, fetchBrandActions, setError, setModified, setSucceeded, uploadBrandImage, updateAssignBrand } from "../../store/slices/brandSlice.tsx"
import { Alert } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { getUser } from "../../provider/AuthProvider";

const OptionsModal = ({ closeModal, isOpen, isEdit }) => {
  const dispatch = useAppDispatch();
  const userAuth = new useAuthContext();
  const { succeeded, processing, modified, error, allBrands } = useAppSelector(store => store.brandSlice);
  const [option, setOption] = useState(0);
  const [Brand, setBrand] = useState("");
  const [BrandSub, setBrandSub] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [Brandfile, setBrandfile] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  // useEffect(() => {
  //   dispatch(setError(''));
  //   dispatch(setModified(false));
  //   dispatch(setProcessing(false));
  //   setBrand('')
  //   setOption(0)
  //   setBrandSub('');
  //   setSelectedBrand('')
  // }, [isEdit]);

  // useEffect(() => {
  //   dispatch(setError(''));
  //   dispatch(setModified(false));
  //   dispatch(setProcessing(false));
  //   setOption(0);
  //   setShowSuccess(false);
  //   // Delayed dispatch after 5 seconds
  //   const timeoutId = setTimeout(() => {
  //     dispatch(setSucceeded(false));
  //   }, 3000);

  //   return () => clearTimeout(timeoutId);
  // }, [succeeded]);

  // Reset states when modal opens
  useEffect(() => {
    if (isOpen) {
      dispatch(setError(''));
      dispatch(setModified(false));
      dispatch(setProcessing(false));
      dispatch(setSucceeded(false));
      setShowSuccess(false);
      setBrand('');
      setOption(0);
      setBrandSub('');
      setSelectedBrand('');
      setBrandfile(null);
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (succeeded) {
      setShowSuccess(true);
      const timeoutId = setTimeout(() => {
        dispatch(setSucceeded(false));
        setShowSuccess(false);
        closeModal();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [succeeded, dispatch]);

  const handleImageUpload = (file) => {
    if (file && !file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setBrandfile(null)
      dispatch(setError('Invalid file format. Please upload a JPG, JPEG, or PNG file.'));
    } else {
      setBrandfile(file)
      dispatch(setError(''));
    }
  }

  const callYesClick = async () => {
    if (processing) return;

    let FK_CompanyID = isEdit?.CompanyID || allBrands?.[0]?.FK_CompanyID;

    const checkDuplicateBrand = (dataArray, errorMessage) => {
      const isDuplicateBrand = dataArray.some((o) => o.Brand === Brand && o.FK_CompanyID === FK_CompanyID);
      if (isDuplicateBrand) {
        dispatch(setError(errorMessage));
      }
      return isDuplicateBrand;
    };

    const checkAndSetDuplicateError = (dataArray, errorMessage) => {
      const isDuplicateBrand = checkDuplicateBrand(dataArray, errorMessage);
      return isDuplicateBrand;
    };

    try {
      setProcessing(true);

      if ((option === 2 && selectedBrand === "") || (option === 3 && Brand === "")) {
        dispatch(setError(option === 2 ? 'Please select substitute' : 'Brand is required'));
        return;
      }

      let payload = null;

      if (option === 1) {
        payload = { BrandID: isEdit?.BrandID, ValidBrand: 1, Substitute: null, AuditUserID: getUser().UserID };
      } else if (option === 2) {
        payload = { BrandID: isEdit?.BrandID, ValidBrand: 0, Substitute: selectedBrand, AuditUserID: getUser().UserID};
      } else {
        payload = { FK_CompanyID: isEdit?.CompanyID, Brand, BrandSub, ValidBrand: 1, AuditUserID: getUser().UserID};
      }

      if (option === 3) {
        const duplicateBrand = checkAndSetDuplicateError(allBrands, 'Brand already exists');

        if (!duplicateBrand) {
          const json = await dispatch(fetchBrandActions(payload));

          if (json?.BrandID) {
            await dispatch(fetchBrandActions({
              BrandID: isEdit?.BrandID,
              ValidBrand: 0,
              Substitute: json?.BrandID,
              AuditUserID: getUser().UserID
            }));
          }

          if (Brandfile && json?.BrandID) {
            await dispatch(uploadBrandImage({ id: json?.BrandID, type: "brand", image: Brandfile }));
          }

          //Brand Re-Assign API
          const reAssignBrandPayload = {CompanyID: isEdit?.CompanyID, Existing_BrandID: isEdit?.BrandID, New_BrandID: json?.BrandID }
          const assignResponse = await dispatch(updateAssignBrand(reAssignBrandPayload));

          if(typeof assignResponse.error === "undefined"){
            dispatch(setError(assignResponse.error));
            return ;
          }else{
            dispatch(setSucceeded(true));
          }
        }
      } else {
        const json = await dispatch(fetchBrandActions(payload));

        //Brand Re-Assign API
        if(option === 2) {
          const reAssignBrandPayload = {CompanyID: isEdit?.CompanyID, Existing_BrandID: isEdit?.BrandID, New_BrandID: selectedBrand }
          const assignResponse = await dispatch(updateAssignBrand(reAssignBrandPayload));
          if(typeof assignResponse.error === "undefined"){
            dispatch(setError(assignResponse.error));
            return ;
          }else {
            dispatch(setSucceeded(true));
          }
        }
      }

      
      closeModal();

    } catch (error) {
      console.error('Error in callYesClick:', error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col justify-center items-center">
                    <h1 className="text-2xl font-[600] ">Options</h1>
                  </div>
                  <div onClick={closeModal} className="cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
                <div className="flex divide-y divide-black flex-col items-cnter">
                  <div className="flex py-3 items-center gap-2">
                    <input type="radio" className="w-4 h-4" name="option" value={1} onChange={() => {
                      setOption(1)
                      dispatch(setModified(true))
                    }} />
                    <label>{isEdit?.Brand} - {isEdit?.BrandSub} is a Verified Brand:</label>
                  </div>
                  <div className="flex py-3 flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <input type="radio" className="w-4 h-4" name="option" value={2} onChange={() => {
                        setOption(2)
                        dispatch(setModified(true))
                      }} />
                      <label>Existing Verified Brand:</label>
                    </div>
                    <select name="select" value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)} className="border style rounded-md py-1.5 px-2" disabled={option === 1 || option === 3}>
                      <option value="" key="default">--select--</option>
                      {/* {
                        allBrands?.length > 0 && allBrands.filter((o) => o.ValidBrand === 1).map(value => ((
                          <option value={value.BrandID}>{value.Brand} - {value.BrandSub}</option>
                        )))
                      } */}
                        {allBrands?.length > 0 && allBrands
                          .filter((o) => o.ValidBrand === 1)
                          .map(value => (
                            <option key={value.BrandID} value={value.BrandID}>
                              {value.Brand} - {value.BrandSub}
                            </option>
                          ))
                        }

                    </select>
                  </div>
                  <div className="flex py-3 flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <input type="radio" className="w-4 h-4" name="option" value={3} onChange={() => {
                          setOption(3)
                          dispatch(setModified(true))
                        }} />
                        <label>Create a new Brand</label>
                      </div>
                      <div className="flex py-3 items-center gap-2">
                        <label className="shrink-0">Brand:</label>
                        <input
                          disabled={option === 2 || option === 1}
                          type="text"
                          name="brandName"
                          value={Brand}
                          placeholder="Brand"
                          className="border grow rounded-md py-1.5 px-2"
                          onChange={(e) => setBrand(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex py-3 items-center gap-2">
                      <label className="shrink-0">Sub-Brand:</label>
                      <input
                        disabled={option === 2 || option === 1}
                        type="text"
                        name="brandName"
                        value={BrandSub}
                        placeholder="Sub-Brand"
                        className="border grow rounded-md py-1.5 px-2"
                        onChange={(e) => setBrandSub(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label>Brand Logo</label>
                      <input
                        disabled={option === 2 || option === 1}
                        type="file"
                        name={'image'}
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e.target.files[0])}
                        className="border rounded-md py-1.5 px-2"
                      />
                    </div>
                  </div>
                </div>
                {
                  error &&
                  <Alert severity="error">{error}</Alert>
                }
                {
                  (succeeded || showSuccess) &&
                  <Alert severity="success">Changes Saved Successfully</Alert>
                }
                <div className="flex justify-end items-center gap-2 mt-3">
                  <button
                    onClick={closeModal}
                    className="bg-gray-500 px-4 rounded py-1.5 text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={callYesClick}
                    className={`${processing || !modified ? "bg-gray-500" : "bg-primary"}  px-4 rounded py-1.5 text-white`}
                    disabled={processing || !modified}
                  >
                    {processing ? "Loading..." : "Save"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OptionsModal;
