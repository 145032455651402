import React, { useContext } from "react";
import { DashboardPopUpContext } from "../../../dashboard-components/context/DashboardPopUpContext";
import PrefixModal from "./PrefixModal";

const PrefixClickable = ({
  prefix,
  openPrefixModal,
  showPrefixModal,
  closePrefixModal,
}) => {
  const { showPopUp } = useContext(DashboardPopUpContext);

  const showPopUpModal = () => {
    if (!prefix.Compromised) return;

    openPrefixModal();
  };

  const style = {
    color: prefix.Compromised === true ? "red" : "black",
    fontWeight: prefix.Compromised === "bold",
    cursor: prefix.Compromised === true ? "pointer" : "auto",
  };

  return (
    <>
      <p onClick={showPopUpModal} style={style}>
        {prefix.Prefix}
      </p>
      <PrefixModal
        prefix={prefix}
        showPrefixModal={showPrefixModal}
        closePrefixModal={closePrefixModal}
      />
    </>
  );
};

export default PrefixClickable;
