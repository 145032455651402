import React from "react";

// Function to return an array of menu items for the employer side menu.
export const SideMenuEmployee = () => {
  return [
    {
      // Dashboard menu item
      title: "Dashboard",
      name: "Dashboard",
      parent: true,
      adopt: "",
      icon: "i-Bar-Chart",
      link: "/DashboardHome",
    },
    {
      // My Company menu item
      title: "GVD Editor",
      name: "GVD Editor",
      parent: false,
      adopt: "",
      icon: "company",
      link: "/GVDEditor",
    },
    {
      // My Company menu item
      title: "Product Scan",
      name: "Product Scan",
      parent: false,
      adopt: "",
      icon: "company",
      link: "/productscan",
    },
    {
      // My Company menu item
      title: "Validate GVD",
      name: "Validate GVD",
      parent: false,
      adopt: "",
      icon: "company",
      link: "/ValidateGVD",
    },
    {
      // My Company menu item
      title: "My Company",
      name: "My Company",
      parent: false,
      adopt: "",
      icon: "company",
      link: "/Account-Details",
    },
    {
      // Admin Area menu item with sub-items
      title: "Admin Area",
      name: "Admin Area",
      parent: false,
      adopt: "",
      icon: "list",
      child: [
        {
          // Signup & Applications sub-item under Admin Area
          title: "Signup & Applications",
          name: "Signup & Applications",
          parent: false,
          adopt: "Admin Area",
          icon: "arrow-right",
          child: [
            {
              // Coupon Administration sub-sub-item under Signup & Applications
              title: "Coupon Administration",
              name: "Coupon Administration",
              link: "/CouponAdministration",
              adopt: "Signup & Applications",
              icon: "",
            },
            {
              // Signup Applications sub-sub-item under Signup & Applications
              title: "Signup Applications",
              name: "Signup Applications",
              link: "/SignUpApprovalTab/Starting",
              adopt: "Signup & Applications",
              icon: "",
            },
            {
              // New Member Onboarding sub-sub-item under Signup & Applications
              title: "New Member Onboarding",
              name: "New Member Onboarding",
              link: "/NewMemberOnboarding",
              adopt: "Signup & Applications",
              icon: "",
            },
          ],
        },
        {
          // Companies sub-item under Admin Area
          title: "Companies",
          name: "Companies",
          parent: false,
          adopt: "Admin Area",
          icon: "arrow-right",
          child: [
            {
              // Parent Companies sub-sub-item under Companies
              title: "Parent Companies",
              name: "Parent Companies",
              link: "/ParentCompanies",
              icon: "",
            },
            {
              // Companies sub-sub-item under Companies
              title: "Companies",
              name: "Companies",
              link: "/Companies",
              icon: "",
            },
            {
              // Company Relationships sub-sub-item under Companies
              title: "Company Relationships",
              name: "Company Relationships",
              link: "/CompanyRelationships",
              icon: "",
            },
          ],
        },
        {
          // Compromised Prefixes sub-item under Admin Area
          title: "Compromised Prefixes",
          name: "Compromised Prefixes",
          parent: false,
          adopt: "Admin Area",
          icon: "arrow-right",
          child: [
            {
              // View Compromised Prefixes sub-sub-item under Compromised Prefixes
              title: "View Compromised Prefixes",
              name: "View Compromised Prefixes",
              link: "/ViewCompromisedPrefixes",
              icon: "",
            },
            {
              // Compromised Prefix Matrix sub-sub-item under Compromised Prefixes
              title: "Compromised Prefix Matrix",
              name: "Compromised Prefix Matrix",
              link: "/CompromisedPrefixMatrix",
              icon: "",
            },
          ],
        },
      ],
    },
    {
      // Product Browser menu item
      title: "Product Browser",
      name: "Product Browser",
      parent: true,
      adopt: "",
      icon: "product-browse",
      link: "/productBrowser",
    },
    {
      // My Profile menu item with sub-items
      title: "My Profile",
      name: "My Profile",
      parent: true,
      adopt: "",
      icon: "profile",
      child: [
        {
          // My Details sub-item under My Profile
          title: "My Details",
          name: "My Details",
          parent: true,
          link: "/User/UserProfile",
        },
        {
          // Logout sub-item under My Profile
          title: "logout",
          name: "logout",
          parent: true,
          link: "/",
        },
      ],
    },
  ];
};
