import React, { useContext } from "react";
import { DashboardPopUpContext } from "../../../dashboard-components/context/DashboardPopUpContext";
import { BarcodeSearchButton } from "./ProductScanNewStyle";
import ReclassifyModal from "./ReclassifyModal";
import editIcon from "../../../assets/icons/edit.png";

const ButtonShowEditReclassifyModal = (props) => {
  const { disabled } = props;
  return (
    <>
      <img
        src={editIcon}
        alt="Edit"
        width="16"
        height="16"
        // className="cursor-pointer hover:opacity-80"
        // onClick={props.openClassifyModal}
        className={`${
          disabled
            ? "opacity-50 cursor-not-allowed"
            : "cursor-pointer hover:opacity-80"
        }`}
        onClick={disabled ? undefined : props.openClassifyModal}
      />
      <ReclassifyModal {...props} />
    </>
  );
};

export default ButtonShowEditReclassifyModal;
