import React, { Fragment, useEffect, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from "../../store/store";
import { setProcessing, fetchBrandActions, setError, setModified, setSucceeded, uploadBrandImage } from "../../store/slices/brandSlice.tsx"
import { Alert } from '@mui/material';
import { useAuthContext } from '../../context/AuthContext';

const CreateEditModal = ({ closeModal, isOpen, isEdit }) => {
  const userAuth = new useAuthContext();
  const dispatch = useAppDispatch();
  const { succeeded, modified, processing, error, allBrands } = useAppSelector(store => store.brandSlice);
  const [Brand, setBrand] = useState("");
  const [HideImage, setHideImage] = useState(false);
  const [BrandSub, setBrandSub] = useState("");
  const [Brandfile, setBrandfile] = useState(null);


  useEffect(() => {
    // Check if isEdit is true and has brand and subBrand
    if (isEdit && isEdit.Brand) {
      setBrand(isEdit.Brand);
      if (isEdit.BrandSub) {
        setBrandSub(isEdit?.BrandSub);
      }
    } else {
      setBrand('');
      setBrandSub('');
      setHideImage(false)
    }
    dispatch(setProcessing(false))
  }, [isEdit, isOpen]);

  useEffect(() => {
    dispatch(setError(''));
    dispatch(setModified(false));
    dispatch(setProcessing(false));

    // Delayed dispatch after 5 seconds
    const timeoutId = setTimeout(() => {
      dispatch(setSucceeded(false));
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [succeeded]);

  const handleChange = (fieldName, value) => {
    if (fieldName === 'Brand') {
      setBrand(value);
    } else if (fieldName === 'Sub-Brand') {
      setBrandSub(value);
    } else if (fieldName === 'Image') {
      if (isEdit?.BrandID) {
        if (value && !value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          dispatch(setError('Invalid file format. Please upload a JPG, JPEG, or PNG file.'));
          return;
        } else {
          uploadImageFuntion(isEdit?.BrandID, value)
        }
      }
      setBrandfile(value);
    }
    dispatch(setModified(true));

  };

  const uploadImageFuntion = async (BrandID, file = null) => {
    await dispatch(uploadBrandImage({
      id: BrandID,
      type: "brand",
      image: Brandfile ?? file
    }))
  }

  const callYesClick = async () => {
    dispatch(setProcessing(true));

    if (Brand === "" || Brand === undefined) {
      dispatch(setError('Brand is required.'));
    } else {
      let FK_CompanyID = isEdit?.CompanyID || allBrands?.[0]?.FK_CompanyID;

      const checkDuplicateBrand = (dataArray) => {
        return dataArray.some((o) => o.Brand === Brand && o.FK_CompanyID === FK_CompanyID);
      };

      const checkAndSetDuplicateError = (dataArray, errorMessage) => {
        const isDuplicateBrand = checkDuplicateBrand(dataArray);

        if (isDuplicateBrand) {
          dispatch(setError(errorMessage));
        }

        return isDuplicateBrand;
      };

      let duplicateBrand = false;

      duplicateBrand = checkAndSetDuplicateError(allBrands, 'Brand already exists');

      if (!FK_CompanyID) {
        dispatch(setError('Company ID not found.'));
      } else {
        if (isEdit === null) {
          if (!duplicateBrand) {
            const json = await dispatch(fetchBrandActions({
              FK_CompanyID,
              Brand,
              BrandSub,
              ValidBrand: 1,
              AuditUserID: userAuth?.user?.UserID
            }));

            if (Brandfile && json?.BrandID) {
              if (Brandfile && !Brandfile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                dispatch(setError('The file format is not valid, and the image could not be uploaded.'));
                dispatch(setProcessing(false));
                return;
              } else {
                uploadImageFuntion(json?.BrandID)
              }
            }

            if (json) {
              closeModal();
            }
          }
        } else if (isEdit?.BrandID) {
          if (!duplicateBrand) {
            await dispatch(fetchBrandActions({
              FK_CompanyID: isEdit?.CompanyID,
              BrandID: isEdit?.BrandID,
              Brand,
              BrandSub,
              ValidBrand: 1,
              AuditUserID: userAuth?.user?.UserID
            }));
            const json = await dispatch(fetchBrandActions({
              FK_CompanyID,
              Brand: isEdit.Brand,
              Substitute: isEdit?.BrandID,
              AuditUserID: userAuth?.user?.UserID
            }));

            if (json) {
              closeModal();
            }
          }
        }
      }

      dispatch(setProcessing(false));
    }
  };



  const callNoClick = async () => {
    closeModal();
  };

  const clearImageHandler = async () => {
    setHideImage(true);

    const json = await dispatch(fetchBrandActions({
      BrandID: isEdit?.BrandID,
      BrandImageURL: null,
      AuditUserID: userAuth?.user?.UserID
    }));

    // closeModal()
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col justify-center items-center">
                    <h1 className="text-2xl font-[600]">{`${isEdit === null ? 'Create New' : 'Edit '}  Brand`}</h1>
                  </div>
                  <div onClick={closeModal} className="cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col items-cnter">
                  {['Brand', 'Sub-Brand', 'Image'].map((fieldName) => (
                    <div key={fieldName} className="flex flex-col gap-1 my-2">
                      {
                        fieldName === 'Image' ?
                          <>
                            <label className="text-base font-[600]">Brand Logo:</label>
                            <input
                              type="file"
                              name={fieldName.toLowerCase()}
                              placeholder={`${fieldName}`}
                              className="border rounded-md py-1.5 px-2"
                              accept="image/*"
                              onChange={(e) => handleChange(fieldName, e.target.files[0])}
                            />
                          </>
                          :
                          <>
                            <label className="text-base font-[600]">{`${fieldName} Name:`}</label>
                            <input
                              type="text"
                              name={fieldName.toLowerCase()}
                              value={fieldName === 'Brand' ? Brand : BrandSub}
                              placeholder={`${fieldName} Name`}
                              className="border rounded-md py-1.5 px-2"
                              onChange={(e) => handleChange(fieldName, e.target.value)}
                            />
                          </>
                      }
                    </div>
                  ))}

                  {isEdit?.BrandImageURL && isEdit?.BrandImageURL !== "null" && !HideImage && (
                    <div className='flex w-max mx-auto justify-center  mt-4 items-center relative'>
                      <div className='absolute -right-5 -top-5 cursor-pointer'>
                        <div onClick={() => clearImageHandler()} className="cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                      </div>
                      <div className="container">
                        <img
                          src={isEdit?.BrandImageURL}
                          alt={isEdit?.BrandID}
                          className={`w-20 h-20 object-contain border enlarged-image`}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {
                  error?.length > 0 &&
                  <Alert severity="error">{error}</Alert>
                }
                {
                  succeeded &&
                  <Alert severity="success">Changes Saved Successfully</Alert>
                }
                <div className="flex items-center justify-end gap-2 mt-3">
                  <button onClick={callNoClick} className="bg-gray-500 px-4 rounded py-1.5 text-white">
                    Cancel
                  </button>
                  <button
                    disabled={!modified || processing}
                    onClick={callYesClick}
                    className={`${processing ? "bg-gray-500" : !modified ? "bg-gray-500" : "bg-primary"
                      } px-4 rounded py-1.5 text-white`}
                  >
                    {processing ? 'Loading...' : 'Save'}
                  </button>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition >
  );
};

export default CreateEditModal;
